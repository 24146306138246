import { FC, useEffect, useState } from "react"
import queryString from "query-string"
import { useSentry } from "@heyhabito/sentry"
import config from "../../config.json"

import { WrappedBase } from "../shared-components/Shared/WrappedBase"

const SignIn: FC = () => {
  const { logToSentry } = useSentry()

  function redirectToAuthFlows(token: string | null): void {
    const queryParams = queryString.parse(location.search)

    const destinationOverride = queryParams.destination
      ? "&destination=" + queryParams.destination
      : ""

    window.location.replace(
      config.authURL +
        "/commence-login?login&token=" +
        token +
        destinationOverride
    )
  }

  const [isRegisteringToken, setIsRegisteringToken] = useState<boolean>(false)

  useEffect(() => {
    if (
      window !== undefined &&
      document !== undefined &&
      localStorage !== undefined
    ) {
      if (isRegisteringToken) return

      setIsRegisteringToken(true)

      let token = localStorage.getItem("habito/token")

      if (token === null) {
        fetch(config.apiBaseURL + "/sessions", {
          method: "POST",
        })
          .then(res => {
            if (res.status !== 200) {
              res.text().then(text => {
                logToSentry(
                  `sessions call returned status ${res.status}`,
                  new Error(text)
                )
              })
            } else {
              res.json().then(json => {
                token = json.token
                redirectToAuthFlows(token)
              })
            }
          })
          .catch(e => {
            logToSentry(`failed to call sessions endpoint`, e)
          })
      } else {
        redirectToAuthFlows(token)
      }
    }
  }, [isRegisteringToken, logToSentry])

  return (
    <WrappedBase
      metaTitle="Sign in"
      metaDescription=""
      canonicalUrl="https://www.habito.com/sign-in"
      noIndex={true}
      pageName="Sign in"
      intercom={false}
      config={config}
    />
  )
}

export default SignIn
